.wrapper-model.wrapper-m3 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 30em;
    grid-template-areas:
        'game-controls game-controls'
        'game-board game-board';
    gap: 1px;
}

.game-control-m3 {
    grid-area: game-controls;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'start-game end-game';
    gap: 10px;
    padding: 10px;
    background: blue;
}

.start-game-m3 {
    background: cornflowerblue;
    grid-area: start-game;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.end-game-m3 {
    background: darkgoldenrod;
    grid-area: end-game;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.game-board-m3 {
    grid-area: game-board;
    display: grid;
    grid-template-columns: 1fr 8fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas:
        'close-game-grid game-grid'
        'open-game-grid game-grid'
        'select-avatar game-grid'
        'skip-game game-grid';
    gap: 10px;
    padding: 10px;
    background: whitesmoke;
}

.close-game-grid-m3 {
    background: yellow;
    grid-area: close-game-grid;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.open-game-grid-m3 {
    background: tomato;
    grid-area: open-game-grid;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.select-avatar-m3 {
    background: chartreuse;
    grid-area: select-avatar;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.skip-game-m3 {
    background: chocolate;
    grid-area: skip-game;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.game-grid-m3 {
    background: violet;
    grid-area: game-grid;
    display: grid;
    grid-template-rows: repeat(7, 1fr);
    grid-template-columns: repeat(7, 1fr);

    gap: 7px;
    padding: 7px;
}
.game-grid-cell {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 200%;
    cursor: pointer;
}
