:root {
    --background-general: white;
    --topbar-background: #00a8ff;
    --topbar-color: white;
    --sidebar-background: white;
    --sidebar-nav-icon-height: 70px;
    --sidebar-menu-item-color: #00a8ff;
    --sidebar-sub-menu-background: white;
    --content-background-wheat: #d2dae2;
    --footer-background-grey: dimgrey;
    --topbar-height: 70px;
    --sidebar-width: 70px;
    --icon-button-height: 70px;
    --icon-button-width: 70px;
    --dark-theme-background: #2c3e50;
    --dark-theme-color: #fcf2ff;
    --dark-theme-border-color: yellowgreen;
    --dark-theme-bulb-color: yellow;
    --footer-color: white;
    --footer-background: dimgrey;
    --main-background: white;
}

.wrapper {
    min-height: 100vh;
    position: relative;
}

.wrapper-model {
    padding: 10px;
    max-width: 555px;
    margin: auto;
    background: var(--background-general);
}

.display-none {
    display: none;
}

/******************************************************/
/* TOP BANNER *****************************************/
/******************************************************/
.top-banner {
    grid-area: top-banner;
    width: 100%;
    max-width: 555px;
    margin: auto;
}
.top-banner-image {
    width: 100%;
}

.top-banner-2 {
    grid-area: top-banner-2;
    margin: auto;
    max-width: 555px;
}

/******************************************************/
/* AD BOTTOM ******************************************/
/******************************************************/
.ad-bottom {
    grid-area: ad-bottom;
    width: 100%;
    max-width: 555px;
    margin: auto;
}
/******************************************************/
/* SIDEBAR ********************************************/
/******************************************************/
.sidebar {
    grid-area: sidebar;
    background: var(--sidebar-background);
    width: var(--sidebar-width);
    position: relative;
    z-index: 1;
    position: absolute;
    top: var(--topbar-height);
    height: calc(100% - var(--topbar-height));
    box-shadow: 1px 0 5px -2px #888;
    transition: width 0.333s;
}
.sidebar-menu {
    background: var(--sidebar-background);
    overflow-y: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
}
.sidebar-menu-item {
    background: var(--sidebar-background);
    height: var(--topbar-height);
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--sidebar-width);
    color: var(--sidebar-menu-item-color);
}
.sidebar-hidden {
    width: 0;
}

/******************************************************/
/* SUB MOBILE MENU ****************************************/
/******************************************************/
.sidebar-sub-menu-item {
    background: var(--sidebar-sub-menu-background);
    width: 500px;
    position: absolute;
    left: var(--sidebar-width);
    top: var(--topbar-height);
}

.sub-menu-open {
    background: var(--sidebar-sub-menu-background);
}

.sidebar-sub-menu-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.1fr 1fr;
    grid-template-areas:
        'sidebar-sub-menu-title sidebar-sub-menu-title'
        'sidebar-sub-menu-description sidebar-sub-menu-mockup';
    gap: 30px;
    padding: 10px;
}

.sidebar-sub-menu-title {
    grid-area: sidebar-sub-menu-title;
    text-align: center;
}

.sidebar-sub-menu-description {
    grid-area: sidebar-sub-menu-description;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/******************************************************/
/* MOBILE MENU ****************************************/
/******************************************************/
.mobile-menu {
    position: absolute;
    top: var(--topbar-height);
    width: 100%;
    z-index: 3;
}
.mobile-menu > a {
    text-decoration: none;
}
.mobile-menu-item {
    background: var(--sidebar-background);
    height: var(--topbar-height);
    border-bottom: 1px solid var(--topbar-background);
    display: grid;
    grid-template-columns: var(--sidebar-width) 1fr;
    grid-template-areas: 'mobile-menu-item-icon mobile-menu-item-title';
    color: var(--sidebar-menu-item-color);
}
.mobile-menu-item-icon {
    grid-area: mobile-menu-item-icon;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mobile-menu-item-title {
    grid-area: mobile-menu-item-title;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(var(--sidebar-width) * 2.33);
    background: var(--sidebar-background);
    text-decoration: none;
}

/******************************************************/
/* HEADER *********************************************/
/******************************************************/
.header {
    grid-area: header;
    display: grid;
    grid-template-areas: 'header-menu-button website-title theme-switch locale-widget';
    height: var(--topbar-height);
    background: var(--topbar-background);
    color: var(--topbar-color);
    z-index: 1;
    position: relative;
}
.header-menu-button {
    grid-area: header-menu-button;
    background: var(--topbar-background);
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--icon-button-height);
}
.header-menu-button-icon {
    color: var(--topbar-color);
}
.header-website-title {
    grid-area: website-title;
    background: var(--topbar-background);
    display: flex;
    align-items: center;
}
.header-website-title-anchor {
    text-decoration: none;
    color: var(--topbar-color);
    padding: 3px;
}
.header-title {
    padding-left: 20px;
}

/******************************************************/
/* DARK THEME SWITCH **********************************/
/******************************************************/

.dark-mode-icon {
    color: var(--background-general);
}
.theme-switch {
    grid-area: theme-switch;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.switch-theme-mode-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--topbar-color);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    background: transparent;
}
.theme-switch-new {
    grid-area: theme-switch;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/*********************************************************/
/** LOCALE WIDGET ****************************************/
/*********************************************************/
.locale-widget {
    grid-area: locale-widget;
    position: relative;
}
.locale-widget-int {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 29.555%;
    left: 18.181818%;
    background: white;
    padding: 0 4px 0 4px;
}

img.locale-flag {
    position: relative;
    margin-top: 4px;
}

.locale-link.hide-locale-widget {
    display: none;
}

.locale-link-current,
.locale-link-current.show-locale-widget {
    order: -1;
}

/******************************************************/
/* MOBILE BUTTON **************************************/
/******************************************************/

.mobile-menu-button > button > svg {
    color: var(--topbar-color);
}

.mobile-menu-button {
    display: flex;
    justify-content: center;
    align-items: center;
}
/******************************************************/
/* MAIN ***********************************************/
/******************************************************/
.main {
    grid-area: main;
    background: var(--main-background);
    display: flex;
}

/******************************************************/
/* FOOTER *********************************************/
/******************************************************/
.footer {
    grid-area: footer;
    background: var(--footer-background);
    color: var(--footer-color);
    z-index: 1;
    position: relative;
}
.footer-site-details {
    display: flex;
    justify-content: space-around;
    text-decoration: underline;
}
.footer-columns-links {
    display: flex;
    justify-content: space-around;
    margin-left: var(--sidebar-width);
}
.ul-footer-links {
    list-style-type: none;
}
.footer-keyword-list-item-anchor {
    text-decoration: none;
    color: white;
}
.footer-site-details {
    display: flex;
    justify-content: space-around;
    text-decoration: underline;
}

/******************************************************/
/* MOBILE LAYOUT TEMPLATE *****************************/
/******************************************************/

@media only screen and (max-width: 1199.75px) {
    .wrapper {
        grid-template-columns: auto 1fr;
        grid-template-rows: auto 1fr auto auto;
        grid-template-areas:
            'header header'
            'sidebar main'
            'footer'
            'sidebar footer';
    }
    /* hide desktop menu on mobile **/
    .sidebar {
        display: none !important;
    }

    .header {
        grid-area: header;
        grid-template-columns: var(--sidebar-width) 8fr 2fr 2fr;
    }
}

@media only screen and (max-width: 575.75px) {
    .header {
        grid-template-columns: var(--sidebar-width) 6fr 3fr 3fr !important;
    }
    .header-website-title {
        justify-content: center;
    }
}

@media only screen and (max-width: 436.75px) {
    .header {
        grid-template-columns: var(--sidebar-width) 4fr 4fr 4fr !important;
    }
    .header-website-title {
        font-size: 70%;
    }
}

/******************************************************/
/* DESKTOP LAYOUT TEMPLATE ****************************/
/******************************************************/
@media only screen and (min-width: 1200px) {
    /* hide mobile menu on desktop **/
    .mobile-menu {
        display: none !important;
    }
    .wrapper {
        grid-template-columns: auto;
        grid-template-rows: auto auto 500px auto auto auto;
        grid-template-areas:
            'header'
            'top-banner-2'
            'main'
            'ad-bottom'
            'footer';
        position: relative;
    }
    .header {
        grid-area: header;
        grid-template-columns: var(--sidebar-width) 10fr 1fr 1fr !important;
    }
}

/******************************************************/
/* DARK THEME *****************************************/
/******************************************************/
.wrapper.dark-theme-mode * {
    background: var(--dark-theme-background);
    color: var(--dark-theme-color);
    box-shadow: none;
}
.wrapper.dark-theme-mode .game-title,
.wrapper.dark-theme-mode .header {
    border: 1px solid var(--dark-theme-border-color);
}
.wrapper.dark-theme-mode .sidebar {
    border-right: 1px solid var(--dark-theme-border-color);
    border-left: 1px solid var(--dark-theme-border-color);
}
.wrapper.dark-theme-mode .header-website-title {
    border-left: 1px solid var(--dark-theme-border-color);
}
.wrapper.dark-theme-mode .switch-theme {
    border-right: 1px solid var(--dark-theme-border-color);
}
.wrapper.dark-theme-mode .switch-theme-mode-button {
    border: none;
}
.wrapper.dark-theme-mode .switch-theme-mode-button path {
    color: var(--dark-theme-bulb-color);
}
.wrapper.dark-theme-mode .footer {
    border-top: 1px solid var(--dark-theme-border-color);
}

.wrapper.dark-theme-mode .mobile-menu-item {
    border-bottom: 1px solid var(--dark-theme-border-color);
}

.wrapper.dark-theme-mode {
    background: var(--dark-theme-background);
}
