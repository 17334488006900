/*******************************************************/
/** GAME CONTROLS **************************************/
/*******************************************************/

.button-stop-game {
    grid-area: stop-button;
    grid-area: _stop;
}

.button-pause-game {
    grid-area: pause-button;
    grid-area: _pause;
}

.button-stop-game,
.button-pause-game {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    background: #fff;
    color: green;
}

.button-stop-game.not-playing,
.button-pause-game.not-playing {
    opacity: 0.5;
}

.button-stop-game.currently-playing,
.button-pause-game.currently-playing {
    color: green;
}

button.start-game-button {
    align-items: center;
    background: green;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 2vw;
    font-weight: bold;
    justify-content: center;
    padding: 0.5em;
    width: 100%;
    cursor: pointer;
}

button.start-game-button.is-playing {
    opacity: 0.8;
    box-shadow: none;
    color: #16a085;
    background: #f1f2f6;
}
.button-pause-game.is-paused {
    opacity: 0.8;
    box-shadow: none;
    /* color: #16a085; */
    background: #f1f2f6;
}

/*******************************************************/
/** GAME DASHBOARD *************************************/
/*******************************************************/
.game-dashboard-component-wrapper {
    /* background: wheat; */
    grid-area: game-dashboard;
    position: relative;
    display: grid;
    grid-template-areas: 'game-dashboard-grid-layout-intenal';
}
.game-dashboard-component-internal {
    /* background: #c7ecee; */
    background: #f1f2f6;
    padding: 10px;
    display: flex;
    gap: 3px;
    flex-direction: column;
}

/**********************************************************/
/** GAME NOTIFICATIONS ************************************/
/**********************************************************/

#text-with-icon {
    padding: 0 5px 0 5px;
}
.system-message-description {
    display: flex;
    align-items: center;
    font-size: 0.8em;
}

.system-message-description.not-playing {
    color: #fff;
}

.game-notifications-component-wrapper {
    grid-area: game-controls;
    display: grid;
    position: relative;
    grid-template-columns: 10fr 1fr 1fr;
    grid-template-areas: '_start _pause _stop';
    gap: 0.5555px;
}

.game-notifications {
    /* display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center; */
    grid-area: _start;
    padding: 15px;

    display: grid;
    grid-template-columns: 4fr 6.5fr 1.5fr;
    grid-template-areas: 'game-recording game-timer system-messages';
}

.game-notifications.is-not-playing {
    background: green;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    border-radius: 5px;
    cursor: pointer;
}

.game-stopper {
    font-weight: bold;
}

.main-button-element {
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-button-element.main-button-game-status-icon {
    opacity: 1;
    color: white;
}
.main-button-element.main-button-game-status-icon.is-playing {
    opacity: 0.5;
    color: green;
}
/**********************************************************/
/**** GAME TITLE ******************************************/
/**********************************************************/

.game-title {
    grid-area: game-title;
    /* display: flex;
    align-items: center;
    justify-content: end; */
    /* gap: 18px; */
    display: grid;
    grid-template-columns: 10000000fr 1fr 1fr;
}

.how-to-play-link {
    display: flex;
    align-items: center;
    cursor: pointer;
}
/**********************************************************/
/**** GAME SETTINGS AND HELP ******************************/
/**********************************************************/
.how-to-play-modal-description,
.how-to-play-modal-close {
    background: white;
    padding: 10px;
}
.how-to-play-modal-close {
    justify-content: center;
    display: flex;
}
.game-settings-modal,
.how-to-play-modal {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 3px 10px 3px;
    width: 100%;
    z-index: 555;
    box-shadow: var(--box-shadow-game-layout-panel);
    background: #f1f2f6;
    border: 1px solid #54a0ff;
}

.game-setting {
    background: white;
    position: relative;
    padding: 5px;
}

.game-settings-input-error {
    background: red;
    color: white;
}

.error-caption {
    color: red;
}

.game-save-settings {
    grid-area: game-save-settings;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
}

.game-save-settings-ok-message {
    position: absolute;
    bottom: 100%;
    color: green;
}

/*************************************************************************/
/** GAME SCORE ***********************************************************/
/*************************************************************************/
.layout-component-wrapper.game-score {
    grid-area: game-score;
}
.game-score-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: #f1f2f6;
    justify-content: space-between;
    padding: 0 5px 0 5px;
}
.game-score-category {
    font-size: 1em;
}
.game-score-value {
    font-size: 1em;
}
.game-score-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: #f1f2f6;
    height: 100%;
}

.game-timer {
    grid-area: game-timer;
    background: cornflowerblue;
}

/*************************************************************************/
/** GAME BOARD ***********************************************************/
/*************************************************************************/
.game-board-component-wrapper {
    grid-area: game-board;
    grid-template-areas: 'game-board-component-internal';
}
.game-board-component-internal {
    grid-area: game-board-component-internal;
    display: grid;
    grid-template-columns: 8fr 2fr;
    /* grid-template-rows: 8em 8em auto; */
    grid-template-rows: auto auto auto;

    grid-template-areas:
        'number-from-computer close-number'
        'user-number open-number'
        'skip-and-next-number skip-and-next-number';
    gap: 10px;
}

.number-from-computer {
    grid-area: number-from-computer;
    background: #78e08f;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    flex-direction: column;
    gap: 5px;
}

/*************************************************************************/
/** CLOSE NUMBER *********************************************************/
/*************************************************************************/
.close-number {
    height: 100%;
    width: 100%;
}
.close-number {
    grid-area: close-number;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

button.close-number-button {
    border: none;
    /* background: green; */
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    background: #16a085;
    color: #fff;
    font-size: 120%;
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

button.close-number-button:hover,
button.open-number-button:hover {
    filter: contrast(120%);
}

button.close-number-button:active,
button.open-number-button:active {
    filter: contrast(140%);
}

/*************************************************************************/
/** OPEN NUMBER **********************************************************/
/*************************************************************************/
.open-number {
    grid-area: open-number;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.open-number-button {
    border: none;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    background: green;
    background: #16a085;

    color: #fff;
    cursor: pointer;
    font-size: 120%;
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.user-number {
    grid-area: user-number;
    background: #78e08f;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: white;
    flex-direction: column;
}

.correct-input {
    background: chartreuse;
    color: #fff;
}
.skipped-input {
    background: hotpink;
    color: #fff;
}

/****************************************************************************************/
/******* TUTORIALS **********************************************************************/
/****************************************************************************************/

.tutorial-start-button-tooltip {
    top: calc(69% + 20px) !important;
}
.game-tutorial-modal {
    position: fixed;
    background: black;
    opacity: 40%;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

/* z-index works only on positioned elements */
.z-index-tutorial {
    position: relative;
    z-index: 8;
}

.close-number,
.open-number,
.game-dashboard-game-settings {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow-up-tooltip {
    position: absolute;
    top: calc(100% + 20px);
    padding: 10px;
    color: black;
    background: yellow;
}

.arrow-up-tooltip::after {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 50%;
    margin-right: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent yellow transparent;
}

.arrow-right-tooltip {
    position: absolute;
    right: calc(100% + 20px);
    padding: 10px;
    color: black;
    background: yellowgreen;
}
.arrow-right-tooltip::after {
    content: '';
    position: absolute;
    left: 100%;
    top: 50%;
    margin-top: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent yellowgreen;
}
.arrow-left-tooltip {
    position: absolute;
    left: calc(100% + 20px);
    padding: 10px;
    color: black;
    background: pink;
}

.arrow-left-tooltip::after {
    content: '';
    position: absolute;
    right: 100%;
    top: 50%;
    margin-top: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent pink transparent transparent;
}

.arrow-down-tooltip {
    position: absolute;
    bottom: calc(100% + 20px);
    padding: 10px;
    color: white;
    background: blue;
}

.arrow-down-tooltip::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: blue transparent transparent transparent;
}

.tutorial-number-from-computer {
    bottom: 80% !important;
}

.tutorial-number-user-number-correct-tooltip {
    z-index: 888;
    bottom: 80%;
}

._game-tutorial-next-step,
._game-tutorial-prev-step {
    background: inherit;
    color: inherit;
    border: 1px solid white;
    margin: 5px;
}

.tutorial-game-settings-sequence-length-tooltip {
    z-index: 888;
}

/**********************************************************************************/
/******* MSC **********************************************************************/
/**********************************************************************************/
.game-title .how-to-play-link-text {
    font-weight: bold;
}

.skipped-number {
    background: #f1c40f;
    color: white;
}

.game-settings-button {
    border: none;
    background: no-repeat;
    cursor: pointer;
}

.timer-time-area {
    margin-left: 5px;
}

.display-none {
    display: none;
}

.is-playing {
    color: green;
}
.is-not-playing {
    color: white;
}

.tutorial-close-number-button-tooltip {
    background-color: #f1c40f;
    z-index: 888 !important;
}

.tutorial-number-user-number-tooltip {
    z-index: 888 !important;
}

.model-title {
    text-align: center;
}
/*********************************************************************/
/** SKIP AND NEXT NUMBER *********************************************/
/*********************************************************************/

button.skip-number-button,
button.next-number-button {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: none;
    font-size: 120%;
    padding: 10px;
    display: flex;
    gap: 5px;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background: #2e86de;
    color: #fff;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.skip-and-next-number {
    grid-area: skip-and-next-number;
    background: #f1f2f6;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.skip-number,
.next-number {
    height: 100%;
    width: 100%;
    position: relative;
}

.game-element-not-active {
    opacity: 0.3;
}

.number-from-computer-input-and-icon,
.user-input-and-icon {
    display: flex;
    align-items: center;
}

/*********************************************************************/
/** DARK THEME *******************************************************/
/*********************************************************************/

.wrapper.dark-theme-mode .bubbles {
    display: none;
}

.wrapper.dark-theme-mode input,
.wrapper.dark-theme-mode .layout-component-wrapper,
.wrapper.dark-theme-mode .number-from-computer,
.wrapper.dark-theme-mode .user-number {
    border: 1px solid yellowgreen;
}

.wrapper.dark-theme-mode .game-element-not-active {
    opacity: 1;
}

/* .wrapper.dark-theme-mode button {
    background: transparent;
    border: 1px solid yellowgreen;
} */

.wrapper.dark-theme-mode button.start-game-button,
.wrapper.dark-theme-mode button.button-pause-game,
.wrapper.dark-theme-mode button.button-stop-game,
.wrapper.dark-theme-mode .game-notifications.is-not-playing {
    border: 2px solid green;
    color: green;
    background: transparent;
}

.wrapper.dark-theme-mode .start-game-text,
.wrapper.dark-theme-mode .start-game-icon > path,
.wrapper.dark-theme-mode .pause-game-text,
.wrapper.dark-theme-mode .pause-game-icon > path,
.wrapper.dark-theme-mode .stop-game-text,
.wrapper.dark-theme-mode .stop-game-icon > path {
    color: green !important;
}

.wrapper.dark-theme-mode .game-status-icon.is-not-playing > path,
.wrapper.dark-theme-mode .game-notifications.is-not-playing * {
    color: red;
}

.wrapper.dark-theme-mode .number-from-computer *,
.wrapper.dark-theme-mode .user-number * {
    color: #78e08f;
}

.wrapper.dark-theme-mode .number-from-computer,
.wrapper.dark-theme-mode .user-number,
.wrapper.dark-theme-mode input.number-from-computer-input,
.wrapper.dark-theme-mode input.user-number-input {
    border: 1px solid #78e08f;
}

.wrapper.dark-theme-mode .open-number-button,
.wrapper.dark-theme-mode .close-number-button {
    border: 1px solid #16a085;
}

.wrapper.dark-theme-mode .open-number *,
.wrapper.dark-theme-mode .close-number * {
    color: #16a085;
}
.wrapper.dark-theme-mode .game-notifications.is-playing * {
    color: greenyellow;
}

.wrapper.dark-theme-mode button.start-game-button.is-playing {
    border: none !important;
}

.wrapper.dark-theme-mode .skip-and-next-number * {
    color: #2e86de;
}

.wrapper.dark-theme-mode .skip-and-next-number button {
    border: 1px solid #2e86de;
}

/*********************************************************************/
/** NEW USER INPUT ***************************************************/
/*********************************************************************/
.single-digit-box {
    width: 1em;
    font-size: 120%;
}

/* Chrome, Safari, Edge, Opera */
.single-digit-box::-webkit-outer-spin-button,
.single-digit-box::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.single-digit-box[type='number'] {
    -moz-appearance: textfield;
}

.array-of-digit-boxes {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.single-digit-box.new-user-input-box.correct-input {
    background: greenyellow;
    color: white;
}

button,
.number-from-computer,
.user-number {
    border-radius: 5px;
}

/*******************************************************************************/
/* DESKTOP GAME TEMPLATE *******************************************************/
/*******************************************************************************/
:root {
    --box-shadow-game-layout-panel: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.layout-component-wrapper {
    padding: 10px;
    box-shadow: var(--box-shadow-game-layout-panel);
}
.layout-component-internal {
    padding: 10px;
}

.wrapper-model.wrapper-m2 {
    display: grid;
    grid-template-columns: 64fr 8fr;
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
        'game-title game-title'
        'game-controls game-controls'
        'game-board game-score';
    gap: 1px;
    position: relative;
}

/*********************************************************************/
/** MEDIA QUAERIES ***************************************************/
/*********************************************************************/

@media only screen and (max-width: 699px) {
    .wrapper-model.wrapper-m2 {
        display: grid;
        grid-template-columns: 1fr;
        /* grid-template-rows: 0.1fr auto repeat(2, 0.3fr) auto; */
        grid-template-rows: repeat(4, auto);
        grid-template-areas:
            'game-title'
            'game-controls'
            'game-board'
            'game-score';
    }
    .game-dashboard-game-settings {
        flex-direction: row-reverse;
        justify-content: end;
    }
    .game-dashboard-component-internal {
        flex-direction: row;
    }
    .game-title {
        justify-content: end;
    }
    .game-title > h1 {
        font-size: 0.6em;
    }

    .game-title .how-to-play-link-text {
        font-size: 0.6em;
    }
}

@media only screen and (min-width: 576px) {
    input.user-number-input,
    input.number-from-computer-input {
        font-size: 22px;
    }
}

@media only screen and (max-width: 575.98px) {
    .game-notifications {
        flex-direction: row-reverse;
    }
}

@media only screen and (min-width: 767.98px) {
    .game-notifications {
        align-items: center;
    }
}

@media only screen and (min-width: 768px) {
    .stopper-description {
        position: absolute;
    }
}

/** GAME BOARD ***************************/
@media only screen and (max-width: 576px) {
    .game-board-component-internal {
        grid-area: game-board-component-internal;
        display: grid;
        grid-template-rows: 1fr 0.6fr 1fr 0.6fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            'number-from-computer number-from-computer'
            'close-number open-number'
            'user-number user-number'
            'skip-and-next-number skip-and-next-number';
    }
    .model-title {
        font-size: 1em !important;
    }
    .open-number-button,
    .close-number-button {
        flex-direction: row !important;
    }
}
