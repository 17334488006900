:root {
    --top-banner-yellow-text: #fdde31;
    --top-banner-blue-background: #2a7de1;
    --top-banner-pink-left: #f26cb3;
    --top-banner-royal-blue-right: #4b39b5;
}
.top-banner-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border: 1px solid white;
    height: 250px;
}
.top-banner-left {
    background: var(--top-banner-pink-left);
    width: 50%;
    display: grid;
    grid-template-rows: 1fr 7fr 2fr 2fr 1fr;
    grid-template-areas:
        'll'
        'top-banner-cta'
        'top-banner-feature'
        'top-banner-website-url'
        'mm';
}

.top-banner-right {
    background: var(--top-banner-royal-blue-right);
    width: 50%;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.top-banner-cta {
    white-space: pre;
    color: var(--top-banner-royal-blue-right);
    font-weight: bold;
    grid-area: top-banner-cta;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    font-size: 250%;
    line-height: 90%;
    text-transform: capitalize;
    z-index: 2;
}
.top-banner-feature {
    grid-area: top-banner-feature;
    color: var(--top-banner-yellow-text);
    font-weight: bold;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: capitalize;
}
.top-banner-website-url {
    grid-area: top-banner-website-url;
}

/* .top-banner-website-url-box {
    background: var(--top-banner-blue-background);
    color: white;
    width: 90%;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 70%;
    padding: 5px;
    text-align: right;
} */

.top-banner-website-url::after {
    content: 'brainsquatting.com';
    background: var(--top-banner-blue-background);
    color: white;
    width: 90%;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 70%;
    padding: 5px;
    text-align: right;
}

.top-banner-features {
    color: var(--top-banner-yellow-text);
}
ul.features-list {
    list-style: none;
}
.features-list-item {
    display: flex;
    margin-top: 5px;
    font-size: 0.9rem;
}
.top-banner-right-title {
    color: var(--top-banner-yellow-text);
    font-size: 0.8rem;
    padding: 10px 10px 0 10px;
}

@media only screen and (max-width: 500px) {
    .top-banner-cta {
        font-size: 160%;
    }
    .features-list-item {
        font-size: 0.8rem;
    }
}
